

.contactsPage {
    margin-left: 100px;
    max-width: 1000px;
    height: 100%;
    min-height: 90vh;
    margin-top: 13vh;
    color: #343434;
    font-family: 'Source Serif 4', serif;
}

.wrapper {
    min-height: 100vh;
    padding-bottom: 100px;
}

