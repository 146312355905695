
.bannersListWrapper {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    user-select: none;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 5px;
}
.bannerImg {
    max-height: 200px;
    max-width: 200px;
    width: auto;

}
.bannersList {
    gap: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    user-select: none;
    padding: 10px 20px 50px 0;
}

@media (max-width: 688px) {
    .bannersListWrapper {
        order: 1;
        padding: 30px 5px;
    }

    .bannersList {
        padding: 0;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .banner {
        font-size: 22px!important;
        max-width: initial!important;
        width: 100% !important;
    }
    .bannerTime {
        font-size: 16px!important;
    }

    .bannerBtn {
        font-size: 16px!important;
    }
}


@media (max-width: 688px) {

    .bannerImg {
        max-height: 300px;
        max-width: 300px;
        width: auto;

    }

    .bannersList {
        display: flex;
        flex-wrap: wrap;
    }
}

.banner {
    user-select: initial;
    border: rgba(0, 0, 0, 0.07) 1px solid;
    background-color: white;
    position: relative;
    border-radius: 5px;
    padding: 15px 30px 30px 30px;
    font-weight: 300;
    font-size: 18px;
    max-width: 270px;
}
.bannerText {
    user-select: text!important;
    margin-top: 5px;
}
.bannerTime {
    color: rgba(10, 10, 10, 0.66);
    position: absolute;
    top: 0;
    right: 10px;
    font-family: 'Source Serif 4', serif;
    font-size: 14px;
    font-weight: 400;
}

.bannerBtn {
    transition: 300ms;
    text-decoration: none;
    color: black;
    background-color: #efeff2;
    width: fit-content;
    padding: 5px 15px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;

    cursor: pointer;
}

.bannerBtn:hover {
    transition: 300ms;
    color: white;
    background-color: #71092C;
    background-image: linear-gradient(
            45deg,
            hsl(240deg 100% 20%) 0%,
            hsl(289deg 100% 21%) 11%,
            hsl(315deg 100% 27%) 22%,
            hsl(329deg 100% 36%) 33%,
            hsl(337deg 100% 43%) 44%,
            hsl(357deg 91% 59%) 56%,
            hsl(17deg 100% 59%) 67%,
            hsl(34deg 100% 53%) 78%,
            hsl(45deg 100% 50%) 89%,
            hsl(55deg 100% 50%) 100%
    );
}


.textBannerBtn {

}