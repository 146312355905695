

.newsCard {
    transition: width 300ms;
    user-select: none;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0,0,0,.15),0 3px 3px rgba(0,0,0,.15);
    /*max-width: 331px;*/
    /*max-height: 355px;*/
    position: relative;
}

.newsCard:hover {
    box-shadow: 0 0 10px rgba(0,0,0,.2),0 7px 10px rgba(0,0,0,.2);
}

.newsCard img {
    color: rgb(44, 22, 138);
    min-width: 277px;
    position: relative;
    height: 100%;
    width: auto;
    object-fit: cover;
    object-position: center;
    transition: transform 800ms;
}


.newsCard img:hover {
    transform: scale(1.03);
}



.whiteNewsCard {
    position: relative;
    box-shadow: none;
    font-family: 'Source Serif 4', serif;
    background-color: #FFF;
}

.whiteNewsCard:hover {
    box-shadow: none;
}

.newsCardContent {
    padding: 3px;
    display: flex;
    flex-direction: column;
}
.makeDescriptionBlack .newsCardDescription {
    color: white;
}
.wideNewsCard {
    position: relative!important;
}

.wideNewsCard .newsViews .eye {
    color: white!important;
}

.wideNewsCard img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.wideNewsCard .newsCardContent {
    width: 100%;
    position: absolute;
    bottom: 0;
    background: linear-gradient(180deg,transparent,rgba(0,0,0,.85) 80%);
}

.wideNewsCard .newsCardContent p span {
    color: white;
}

.wideNewsCard .newsCardContent .newsType {
    color: white;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.newsViews {
    width: fit-content;
    height: fit-content;
    float: right;
    position: absolute;
    bottom: 2px;
    right: 18px;
}

.eye {
    color: black!important;
}

@media (max-width: 800px)  {
    .newsCardDescriptionWrapper {
        margin: 0 10px 22px 10px!important;
    }
}

.newsCardDescription {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* lines amount */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size:18px;
    font-family: 'Source Serif 4', serif;
    font-weight: 400;

}

.newsCardContent {
    text-overflow: ellipsis;
    max-height: 100px;
}

 .newsCardDescription:hover {
    text-decoration: underline;
}

.newsType {
    padding-left: 10px;
    font-size: 14px;
    font-family: 'Source Serif 4', serif;
    font-weight: 500;
    color: #820000;
    text-transform: uppercase;
}

@media (max-width: 400px)  {
    .newsType {
        display: none;
    }
}